<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-2">
                                <v-select v-model="regionModel" :items="regionLists" item-value="region" item-text="region" label="Region" @change="getOffice" :disabled="isRegionDisabled" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="office_id" item-text="office" label="Office" @change="getSales" :disabled="isMillDisabled" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Sales ID" :disabled="isSalesDisabled" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="periodModel" :items="periodLists" default="" item-value="period" item-text="period" label="Period" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-2">
                                <v-select v-model="typeModel" :items="typeLists" default="" item-value="tr_type" item-text="tr_type" label="Type" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-4">
                                <v-btn class="mt-1" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div :class="['cardResult', isDetailDialogVisible ? 'col-lg-12' : 'col-lg-12']" transition="slide-x-reverse-transition">
                <div class="card">
                    <div class="card-body">
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" height="200px" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.showDetails`]="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip @click="showItemDetails(item)" color="blue" link outlined v-on="on">Details</v-chip>
                                    </template>
                                    <span>Show Detail</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:item.fee="{ item }">
                                {{ formatNoDigit(item.fee) }}
                            </template>
                            <template v-slot:item.hold_param="{ item }">
                                {{ formatTwoDigit(item.hold_param) }}
                            </template>
                            <template v-slot:item.lebih90="{ item }">
                                {{ formatNoDigit(item.lebih90) }}
                            </template>
                            <template v-slot:item.invpaid="{ item }">
                                {{ formatNoDigit(item.invpaid) }}
                            </template>
                            <template v-slot:item.hold="{ item }">
                                {{ formatNoDigit(item.hold) }}
                            </template>
                        </v-data-table>
                    </div>
                </div>
            </div>
            <transition name="slide-x-reverse-transition" mode="out-in">
                <div v-if="isDetailDialogVisible" class="col-lg-6 cardResult">
                    <div class="card">
                        <div class="card-body">
                            <v-data-table 
                            :headers="headersDetails" 
                            :items="itemDetailLists" 
                            class="elevation-1" 
                            page-count="5" 
                            :loading="loadingDatatableDetail" 
                            dense
                            height="400px" 
                            :items-per-page="itemDetailLists.length"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <h4>Detail Paid Invoice</h4>
                                    <v-spacer></v-spacer>
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        @click="close"
                                    >
                                        Hide
                                    </button>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.inv_amt="{ item }">
                                {{ formatNoDigit(item.inv_amt) }}
                            </template>

                            <!-- <template v-slot:item="{ item }">
                                <tr :class="{ 'tr_choice_error': parseFloat(item.persen) > 2 }">
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.destination }}</td>
                                <td>{{ item.exp_descr }}</td>
                                <td>{{ item.driver_id }}</td>
                                <td>{{ item.vehicle_id }}</td>
                                <td>{{ item.JumlahSJ }}</td>
                                <td>{{ item.IDRBarang }}</td>
                                <td>{{ item.IDROngkir }}</td>
                                <td>{{ item.persen }}</td>
                                <td>{{ item.persenWgt }}</td>
                                </tr>
                            </template> -->
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </transition>
            <transition name="slide-x-reverse-transition" mode="out-in">
                <div v-if="isDetailDialogVisible" class="col-lg-6 cardResult">
                    <div class="card">
                        <div class="card-body">
                            <v-data-table 
                            :headers="headersInternalDetails" 
                            :items="itemKepuhDetailLists" 
                            class="elevation-1" 
                            page-count="5" 
                            :loading="loadingDatatableDetail" 
                            dense
                            height="400px" 
                            :items-per-page="itemKepuhDetailLists.length"
                            >
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <h4>Detail OD>90</h4>
                                    <v-spacer></v-spacer>
                                    <button 
                                        type="button" 
                                        class="btn btn-danger" 
                                        @click="close"
                                    >
                                        Hide
                                    </button>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.sisa="{ item }">
                                {{ formatNoDigit(item.sisa) }}
                            </template>
                            <!-- <template v-slot:item="{ item }">
                                <tr :class="{ 'tr_choice_error': parseFloat(item.persen) > 2 }">
                                <td>{{ item.tr_id }}</td>
                                <td>{{ item.destination }}</td>
                                <td>{{ item.exp_descr }}</td>
                                <td>{{ item.driver_id }}</td>
                                <td>{{ item.vehicle_id }}</td>
                                <td>{{ item.JumlahSJ }}</td>
                                <td>{{ item.IDRBarang }}</td>
                                <td>{{ item.IDROngkir }}</td>
                                <td>{{ item.persen }}</td>
                                <td>{{ item.persenWgt }}</td>
                                </tr>
                            </template> -->
                            </v-data-table>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    
</template>

<script>

import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KMB',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Finance',
                disabled: false,
                href: '#',
                },
                {
                text: 'Fee Hold',
                disabled: true,
                href: '#',
                },
            ],
            // entityModel: 'KMB',
            // entityLists: [
            //     { entity_id: 'KMB' },
            // ],
            millModel: '',
            regionModel:'',
            periodModel:'',
            expModel: '',
            millLists: [],
            periodLists: [],
            expLists:[],
            regionLists:[],
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            isRegionDisabled: false,
            isMillDisabled: false,
            isSalesDisabled: false,
            typeModel:'',
            salesModel:'',
            modal: false,
            typeLists:[],
            salesLists:[],
            itemLists: [],
            headers: [
                { text: 'Period', value: 'period', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Region', value: 'region', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Office', value: 'office', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Sales Name', value: 'NamaSales', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Type', value: 'tr_type', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Fee Amt', value: 'fee', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OD >90', value: 'lebih90', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Paid Invoice', value: 'invpaid', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Hold(%)', value: 'hold_param', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'Hold(IDR)', value: 'hold', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            sumDelivNote: '',
            sumTotalGoods: '',
            sumShippingCost: '',
            sumCostRatio: '',
            sumCostWeightRatio: '',
            itemDetailLists: [],
            itemKepuhDetailLists: [],
            headersDetails: [
                { text: 'SALES ID', value: 'salesid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALES NAME', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Order', value: 'CustomerOrderNo', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Nama Customer', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'No Invoice', value: 'noInvoice', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOP', value: 'pay_term_cv', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Invoice Amt', value: 'inv_amt', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            headersInternalDetails: [
                { text: 'SALES ID', value: 'salesid', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALES NAME', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Order', value: 'CustomerOrderNo', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'No Invoice', value: 'noInvoice', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'TOP', value: 'pay_term_cv', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'Jatuh Tempo', value: 'TglJTempo', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OD >90', value: 'sisa', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            isDetailDialogVisible: false,
            isDetailKepuhDialogVisible: false
        }
    },
    async mounted(){
        this.getEnvConf()
        this.getRegion()
        this.getOffice()
        this.getPeriod()
        this.getType()
        this.getSales()
        this.initialize()
        // this.populateExpedisi()
        document.querySelector(".cardResult").style.display = "none"

    },
    methods:{
        async getEnvConf() {
            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderReport`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.isRegionDisabled = true
                } else {
                    this.regionLists = res.data.region
                    this.isRegionDisabled = false
                }
                if(res.data.office.length == 1){
                    this.millModel = res.data.office[0].office_id
                    this.millLists = res.data.office
                    this.isMillDisabled = true
                } else {
                    this.millLists = res.data.office
                    this.isMillDisabled = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.isSalesDisabled = true
                } else {
                    this.salesLists = res.data.sales
                    this.isSalesDisabled = false
                }
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })
        },
        formatTwoDigit(value) {
            if (value) {
                const number = parseFloat(value).toFixed(2);
                const parts = number.split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];
                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return `${formattedInteger},${decimalPart}`;
            }
            return '0.00';
        },
        formatNoDigit(value) {
            if (value) {
                const number = parseFloat(value).toFixed(0);
                const parts = number.split('.');
                const integerPart = parts[0];
                const decimalPart = parts[1];
                const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                return `${formattedInteger}`;
            }
            return '0';
        },
        getCellPropsMinMax(data) {
            if (parseFloat(data.item.persen) > 2) {
                return { class: 'tr_choice_error' };
            }
            return {};
        },
        close() {
            this.isDetailDialogVisible = false; // Menyembunyikan dialog detail
        },
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()
        },

        async getRegion() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/master/kmb/branch_office/region`,
                    null, 
                    false, 
                    false, 
                    false
                );

                console.log("test ",respData.data.data);

                if (respData.data.data) {
                    this.regionLists = respData.data.data;
                } else {
                    this.regionLists = [];
                }
            } catch (error) {
                console.error("Error fetching region data:", error);
                this.regionLists = [];
            } finally {
                this.$store.dispatch("setOverlay", false);
            }
        },

        async getOffice() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/master/kmb/branch_office/office?region=${this.regionModel}`,
                    null, 
                    false, 
                    false, 
                    false
                );

                console.log("test ",respData.data.data);

                if (respData.data.data) {
                    this.millLists = respData.data.data;
                } else {
                    this.millLists = [];
                }
            } catch (error) {
                console.error("Error fetching mill data:", error);
                this.millLists = [];
            } finally {
                this.$store.dispatch("setOverlay", false);
            }
        },

        async getSales() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/master/kmb/sales?office_id=${this.millModel}&active_flag=Y`,
                    null, 
                    false, 
                    false, 
                    false
                );

                console.log("test ",respData.data.data);

                if (respData.data.data) {
                    this.salesLists = respData.data.data;
                } else {
                    this.salesLists = [];
                }
            } catch (error) {
                console.error("Error fetching sales data:", error);
                this.salesLists = [];
            } finally {
                this.$store.dispatch("setOverlay", false);
            }
        },

        async getPeriod() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/master/kmb/fee_hold/getPeriod`,
                    null, 
                    false, 
                    false, 
                    false
                );

                console.log("test ",respData.data.data);

                if (respData.data.data) {
                    this.periodLists = respData.data.data;
                } else {
                    this.periodLists = [];
                }
            } catch (error) {
                console.error("Error fetching period data:", error);
                this.periodLists = [];
            } finally {
                this.$store.dispatch("setOverlay", false);
            }
        },

        async getType() {
            try {
                const respData = await backendApi.fetchCore(
                    `/api/master/kmb/fee_hold/getType`,
                    null, 
                    false, 
                    false, 
                    false
                );

                console.log("test ",respData.data.data);

                if (respData.data.data) {
                    this.typeLists = respData.data.data;
                } else {
                    this.typeLists = [];
                }
            } catch (error) {
                console.error("Error fetching type data:", error);
                this.typeLists = [];
            } finally {
                this.$store.dispatch("setOverlay", false);
            }
        },

        async getData(){

            if(this.millModel == null || this.millModel == '' || this.regionModel == null || this.regionModel == ''){
                Swal.fire({
                        text: 'Please Select Region & Office',
                        icon: 'error',
                    })
                return false
            }

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.isDetailDialogVisible= false,
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/fee_hold`, { 

                    region: this.regionModel ? this.regionModel : '',
                    office_id: this.millModel ? this.millModel : '',
                    salesid: this.salesModel ? this.salesModel : '',
                    period: this.periodModel ? this.periodModel : '',
                    tr_type: this.typeModel ? this.typeModel : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.data){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.data
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
            })

        },

        async showItemDetails(id) {
            // $('#detailModal').modal('show');
            this.$store.dispatch('setOverlay', true)
            this.itemDetailLists = [];
            this.itemKepuhDetailLists = [];
            this.loadingDatatableDetail = true;

            // this.newtr_type= id.tr_type,
            // console.log("TR TYPE", this.newtr_type)

            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/fee_hold/paidInv`,
                {
                    // region: id.region,
                    salesid: id.salesid,
                    period: id.period,
                    tr_type: id.tr_type
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                    },
                }
            )
            .then(res => {
                // this.loadingDatatableDetail = false;
                this.itemDetailLists = res.data.data;
                console.log("paidinv", this.itemDetailLists)
                // this.$store.dispatch('setOverlay', false)
                // this.isDetailDialogVisible = true;
            })

            await axios.post(
                `${process.env.VUE_APP_URL}/api/kmb/fee_hold/holdOD`,
                {
                    // region: id.region,
                    salesid: id.salesid,
                    period: id.period,
                    tr_type: id.tr_type
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`,
                    },
                }
            )
            .then(res => {
                // this.loadingDatatableDetail = false;
                this.itemKepuhDetailLists = res.data.data;
                console.log("lebih90", this.itemKepuhDetailLists)
                 
                // this.$store.dispatch('setOverlay', false)
                // this.isDetailDialogVisible = true;
            })

            this.loadingDatatableDetail = false;
            this.$store.dispatch('setOverlay', false)
            this.isDetailDialogVisible = true;
        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        }

    }
    
}
</script>

<style scoped>
    .tr_choice_error {
        background-color: #f8d7da; 
        color: #721c24; 
    }

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }
    
    .table-responsive {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .styled-table {
        width: 100%;
        border-collapse: collapse;
        margin: 20px 0;
        font-size: 1.2em;
        font-family: Arial, sans-serif;
        box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
    }

    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    .styled-table th {
        background-color: #f2f2f2;
        color: #333;
        font-weight: bold;
    }

    .styled-table tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .styled-table tr:hover {
        background-color: #f1f1f1;
    }

    .styled-table td {
        color: #555;
    }

</style>